<template>
  <div>
    <b-card>
      <validation-observer ref="simpleRules">
        <b-form @input="changeForm()">
          <b-card-header class="d-flex justify-content-start mb-1">
            <span
              class="font-weight-bold"
              style="color: #fefefe; font-size: 16px"
            >{{ $t("GestionGrupos") }} </span>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col>
                <div>
                  <b-form-group
                    :label="$t('PermisosUsuario')"
                    label-for="permissionGroups"
                  >
                    <v-select
                      id="permissionGroups"
                      v-model="selectedPermissionGroups"
                      :options="permissionGroups"
                      multiple
                      label="name"
                      aria-autocomplete="new-password"
                      autocomplete="new-password"
                      :selectable="(option) => forbid ? !option.name.includes(constants.administrador) : true"
                      @input="changeForm()"
                    />
                  </b-form-group>
                </div>
                <div v-if="!isEmpty(selectedPermissionGroups) ? includesPermission(constants.vendor) : false">
                  <b-form-group
                    :label="$t('shopUser')"
                    label-for="profileVendor"
                  >
                    <v-select
                      id="profileVendor"
                      v-model="selectedProfileVendor"
                      :options="vendorGroups"
                      label="vendorName"
                      :placeholder="$t('shopUser')"
                      aria-autocomplete="new-password"
                      autocomplete="new-password"
                      @input="changeForm()"
                    />
                  </b-form-group>
                </div>
              </b-col>
              <b-col>
                <div>
                  <b-form-group
                    v-if="subgroups"
                    :label="$t('Subgroup')"
                    label-for="subgroup"
                  >
                    <v-select
                      id="subgroup"
                      v-model="subgroup"
                      :options="subgroups"
                      label="name"
                      :placeholder="$t('SinGrupoUsuario')"
                      aria-autocomplete="new-password"
                      autocomplete="new-password"
                      @input="changeForm()"
                    />
                  </b-form-group>
                </div>
              </b-col>
            </b-row>
            <b-row
              v-if="!isEmpty(selectedPermissionGroups) ? includesPermission(constants.sponsor) : false"
              class="mt-1"
            >
              <b-col>
                <admin-sponsor-categories @categoriesSelected="categoriesSelected" />
              </b-col>
            </b-row>
            <div class="d-flex justify-content-end">
              <b-button
                variant="success"
                :disabled="disabled"
                class="mb-1 mb-sm-0  float-right mt-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="clck()"
              >
                <feather-icon icon="SaveIcon" />
                {{ $t("dataGeneric.save") }}
              </b-button>
            </div>
          </b-card-body>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>
<script>
import * as constants from '@core/utils/constants'
import axios from '@axios'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import {
  BButton,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  VBTooltip,
  BCardHeader,
  BCardBody,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive' // Notification
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getUserData } from '@/auth/utils'
import { isEmpty, isUserInGroup, showToast } from '@/store/functions'
import i18n from '@/libs/i18n'
import AdminSponsorCategories from '../administrators/AdminSponsorCategories.vue'

let forbid = false
let isShop = false
export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    AdminSponsorCategories,
    ValidationObserver,
    BCardHeader,
    BCardBody,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      router,
      forbid,
      isShop,
      constants,
      isEmpty,
      disabled: true,
      categories: [],
      simpleRules: null,
      userData: null,
      subgroups: null,
      subgroup: null,
      permissionGroups: [],
      selectedPermissionGroups: null,
      initialPermissionGroups: null,
      vendorGroups: null,
      selectedProfileVendor: {},
    }
  },
  mounted() {
    const user = getUserData()
    const loguinUserAdmin = isUserInGroup(user, constants.administrador)
    try {
      if (!loguinUserAdmin) {
        forbid = true
      } else {
        forbid = false
      }

      isShop = user.profile.client.isShop
      this.fetchData()
      // eslint-disable-next-line no-empty
    } catch (error) {

    }
  },
  methods: {
    categoriesSelected(data) {
      this.disabled = false
      this.categories = data
    },
    changeForm() {
      this.disabled = false
    },
    includesPermission(string) {
      return this.selectedPermissionGroups.some(item => item.name === string)
    },
    clck() {
      this.updateUser(this.categories)
      this.disabled = !this.disabled
    },
    fetchData() {
      this.fetchUser()
        .then(response => {
          this.userData = response.data.data.allProfiles.edges[0].node

          const arrayExclude = this.userData.isSuperuser ? [] : constants.exludeList
          const allGroups = response.data.data.allGroups.edges
            .filter(group => {
              const { name } = group.node
              return (
                !arrayExclude.includes(name) // Evita los nombres en arrayExclude
              && ((name === constants.vendor && isShop) // Condición para vendor
                || (name === constants.administrador && !forbid) // Condición para administrador
                || (name !== constants.vendor && name !== constants.administrador)) // Cualquier otro nombre
              )
            })
            .map(element => ({
              node: {
                id: element.node.id,
                name: element.node.name,
              },
            }))

          const vendors = response.data.data.allVendors.edges
          const tempArray = []
          const vendArray = []
          allGroups.forEach(element => {
          // eslint-disable-next-line no-param-reassign
            element.node.name = i18n.t(element.node.name)
            tempArray.push(element.node)
          })
          vendors.forEach(element => {
            vendArray.push(element.node)
          })
          this.permissionGroups = tempArray

          this.vendorGroups = vendArray

          const userGroups = response.data.data.allProfiles.edges[0].node.customuserPtr.groups.edges

          const userVendor = response.data.data.allProfiles.edges[0].node.profileVendor.edges
          this.subgroup = response.data.data.allProfiles.edges[0].node.subgroup
          try {
            const nodesArray = userGroups.map(group => group.node)

            this.initialPermissionGroups = nodesArray
            this.selectedPermissionGroups = nodesArray

            if (forbid) {
              if (this.selectedPermissionGroups.name === constants.administrador) {
                router.push({ name: 'misc-not-authorized' })
              }
            }
          // eslint-disable-next-line no-empty
          } catch (error) {

          }
          this.selectedProfileVendor = userVendor.length === 0 ? null : userVendor[0].node

          this.fetchSubGroups().then(result => {
            const { edges } = result.data.data.allSubgroups
            this.subgroups = edges.map(e => e.node)
          }).catch(err => {
            console.error(err)
          })
        })
        .catch(error => {
          console.error(error)
          if (error.status === 404) {
            this.userData = undefined
          }
        })
    },
    updateUser() {
      if (this.userData.cpassword === null) { this.userData.password = null }

      const initialIdsArray = this.initialPermissionGroups.map(item => item.id)
      const selectedIdsArray = this.selectedPermissionGroups.map(item => item.id)

      const groupsRemove = initialIdsArray.filter(id => !selectedIdsArray.includes(id)) // Items to remove
      const groupsAdd = selectedIdsArray.filter(id => !initialIdsArray.includes(id)) // Items to add
      const data = {
        user: this.userData,
        groupsAdd,
        groupsRemove,
        vendor: this.selectedProfileVendor,
        subgroup: this.subgroup ? this.subgroup.id : '',
        categories: this.categories,
      }
      this.mutateUser(data).then(response => {
        if (response[0].data.data && response[1].data.data) {
          // Comprobar en que orden llegan las respuestas
          // Extrae datos y verifica las respuestas de forma más compacta
          const profile1 = response[0]?.data?.data?.updateProfile?.profile
          const customUser1 = response[0]?.data?.data?.updateCustomuser?.customUser
          const profile2 = response[1]?.data?.data?.updateProfile?.profile
          const customUser2 = response[1]?.data?.data?.updateCustomuser?.customUser

          // Verifica en qué orden llegaron las respuestas y asigna userData
          if (profile1 || customUser1) {
            this.userData = profile1 || customUser1
          }

          if (profile2 || customUser2) {
            this.userData = profile2 || customUser2
          }
          showToast(this.$t('success'), 1, this)
        } else {
          showToast(this.$t('error'), 2, this)
        }
      })
        .catch(error => {
          console.error(error)
          showToast(this.$t('error'), 2, this)
        })
    },
    fetchUser() {
      return new Promise((resolve, reject) => {
        const user = getUserData()
        axios
          .post('', {
            query: `
              {
                allProfiles(id: "${router.currentRoute.params.id}", client:"${user.profile.client.id}")
                {
                  edges {
                    node {
                      id
                      username
                      profileVendor {
                        edges {
                          node {
                            id
                            vendorName
                          }
                        }
                      }
                      subgroup {
                        id
                        name
                      }
                      customuserPtr {
                        id
                        groups {
                          edges {
                            node {
                              id
                              name

                            }
                          }
                        }
                      }
                    }
                  }
                }
                allGroups {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
                allVendors {
                  edges {
                    node {
                      id
                      vendorName
                    }
                  }
                }
              }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSubGroups() {
      const user = getUserData()
      return new Promise((resolve, reject) => {
        axios
          .post('', {
            query: `
            {
              allSubgroups(client:"${user.profile.client.id}",) {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
            `,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    mutateUser(data) {
      const {
        user, groupsAdd, groupsRemove, vendor, categories, subgroup,
      } = data
      const addPermisosString = JSON.stringify(groupsAdd)
      const removePermisosString = JSON.stringify(groupsRemove)
      const dataProfile = new FormData()
      const dataCustomUser = new FormData()

      const config = {
        timeout: 1000 * 60 * 30,
      }
      function getIdArray(array) {
        const res = []
        for (let i = 0; i < array.length; i += 1) {
          res.push(array[i].node.id)
        }
        return res
      }

      const queryUpdateCustomuser = `
      mutation{
        updateCustomuser(id: "${user.id}"
          input: {
              groupsAdd: ${addPermisosString},
              groupsRemove: ${removePermisosString},
              ${user.password != null && user.password.length !== 0 ? `password: "${user.password}",` : ' '}
          }
        ) {
            customUser {
              id
              username
              groups {
                edges {
                  node {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      `
      const queryUpdateProfile = `
        mutation($subgroup:ID,){
          updateProfile(id:"${user.id}",input:{
            subgroup: $subgroup,
            ${!isEmpty(vendor) ? `profileVendor:"${vendor.id}",` : ''}
            sponsorCategory: [${getIdArray(categories)}]}){
            profile{
              id
              username
              profileVendor{
                edges{
                  node{
                    id
                    vendorName
                  }
                }
              }
            }
          }
        }
        `
      const queryProfile = queryUpdateProfile
      const queryCustomUser = queryUpdateCustomuser
      dataProfile.append('query', queryProfile)
      dataCustomUser.append('query', queryCustomUser)

      const variables = {
        subgroup,
      }
      dataProfile.append('variables', JSON.stringify(variables))

      return new Promise((resolve, reject) => {
        const requests = [
          axios.post('', dataProfile, config),
          axios.post('', dataCustomUser, config),
        ]

        // Usamos Promise.allSettled para esperar que ambas solicitudes se completen
        Promise.allSettled(requests)
          .then(results => {
            const errors = results.filter(result => result.status === 'rejected')
            const successes = results.filter(result => result.status === 'fulfilled')

            // Si hay errores, rechazamos la promesa
            if (errors.length > 0) {
              reject(errors.map(e => e.reason)) // Rechazar con los errores
            } else {
              // Si todo fue exitoso, resolvemos con los resultados exitosos
              resolve(successes.map(s => s.value)) // Resolver con los valores exitosos
            }
          })
          .catch(error => {
            // Este catch es para cualquier error no controlado fuera de Promise.allSettled
            console.error('Error general al realizar las solicitudes:', error)
            reject(error) // Rechazar con el error general
          })
      })
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
